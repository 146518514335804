export default () => {
  // const reg = RegExp(/^ms2022-(personal|corp)-(loan|save|confab|pservice|gain|run|cservice|support)/)
  // if (reg.test($('#wrapper').attr('class'))) {
  //   const reg = RegExp(/^ms2022-(personal|corp)-(save|pservice|cservice)-(liquidity_general|saftybox|multi_payment|boxes)/)
  //   if (reg.test($('#wrapper').attr('class'))) {
  //     $('.itemTable').wrap('<div class="ms2022-scrollable-table">')
  //     return
  //   }
  //   $('.itemTable').find('table').wrap('<div class="ms2022-scrollable-table">')
  // } else {
  // }

  if ($('.itemTable').parent('.ms2022-scrollable-table--onecol').length > 0) {
    $('.itemTable').parent().addClass('ms2022-scrollable-table')
    $('.itemTable').find('table').wrap('<div class="ms2022-scrollable-table">')
  } else {
    $('.itemTable').wrap('<div class="ms2022-scrollable-table">')
    $('.itemTable').find('table').wrap('<div class="ms2022-scrollable-table">')
  }
}