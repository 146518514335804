export default () => {
  let reg = RegExp(/^ms2022-corp-cservice-direct /)
  if (reg.test($('#wrapper').attr('class'))) {
    $('.h01 .h01').removeClass('h01')
    $('.h01').after($('.h01 > div'))
    $('.linkBtn').parent().children().wrapAll('<div class="ms2022-linkbtns"></div>')
  }

  reg = RegExp(/^ms2022-corp-cservice-direct-(information|security|regulation|faq) /)
  if (reg.test($('#wrapper').attr('class'))) {
    $('.h01 .h01').removeClass('h01')
    $('.h01').after($('.h01 > div'))
    $('.itemTable').wrap('<div class="ms2022-scrollable-table">')
    $('.linkBtn').parent().children().wrapAll('<div class="ms2022-linkbtns"></div>')
  }

  reg = RegExp(/^ms2022-corp-cservice-direct-(faq001|faq002|faq003|faq004|faq005|faq006|faq007|faq008|faq009|faq010|faq011|faq012) /)
  if (reg.test($('#wrapper').attr('class'))) {
    $('.h01 .h01').removeClass('h01')
    $('.h01').after($('.h01 > div'))
  }

  if ($('#wrapper').hasClass('ms2022-corp-cservice-direct-flow')) {
    $('.h01 .h01').removeClass('h01')
    $('.h01').after($('.h01 > div'))
    $('.block01.block01Wrap.bgColorB').addClass('ms2022-feature')
    $('.linkBtn').parent().children().wrapAll('<div class="ms2022-linkbtns"></div>')
  }

  if ($('#wrapper').hasClass('ms2022-corp-cservice-security')) {
    $('.h01 .h01').removeClass('h01')
    $('.h01').after($('.h01 > div'))
    $('.linkBtn').parent().children().wrapAll('<div class="ms2022-linkbtns"></div>')
  }
  
  if ($('#wrapper').hasClass('ms2022-corp-cservice-flow')) {
    $('.h01 .h01').removeClass('h01')
    $('.h01').after($('.h01 > div'))
    $('.block01.block01Wrap.bgColorB').addClass('ms2022-feature')
    $('.linkBtn').parent().children().wrapAll('<div class="ms2022-linkbtns"></div>')
  }

  if ($('#wrapper').hasClass('ms2022-corp-cservice-nightsafe')) {
    $('.ms2022-scrollable-table').addClass('ms2022-scrollable-table--onecol')
  }
}