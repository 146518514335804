export default (title, updated, url, external, cat) => {
  let catVal, catname
  if (cat.hasClass('iconOshirase')) {
    catVal = 'info'
    catname = 'お知らせ'
  } else if (cat.hasClass('iconJyuyou')) {
    catVal = 'important'
    catname = '重要'
  } else if (cat.hasClass('iconKojin')) {
    catVal = 'personal'
    catname = '個人のお客様'
  } else if (cat.hasClass('iconHoujin')) {
    catVal = 'corporation'
    catname = '法人・事業主のお客様'
  } else if (cat.hasClass('iconSyouhin')) {
    catVal = 'product'
    catname = '商品・サービス'
  } else if (cat.hasClass('iconKigyou')) {
    catVal = 'company'
    catname = '企業・IR'
  } else if (cat.hasClass('iconSonota')) {
    catVal = 'other'
    catname = 'その他'
  }

  return `
    <div class="ms2022-news-list__item">
      <div class="ms2022-news-list__updated">
        <time>${updated}</time>
      </div>
      <div class="ms2022-news-list__cat--${catVal}">${catname}</div>
      <a href="${url}" ${external ? 'target="_blank"' : ''} class="ms2022-news-list__title">${title}</a>
    </div>
  `
}

export function importantTemplate(title, url, external) {
  return `
    <div class="ms2022-news-list__item">
      <a href="${url}" ${external ? 'target="_blank"' : ''} class="ms2022-news-list__title">${title}</a>
    </div>
  `
}