import newsTemplate from '/src/js/components/news-template'

export default () => {
  if ($('#wrapper').hasClass('ms2022-info')) {
    $('.newsArea dl').each(function() {
      const title = $(this).find('dd').text()
      const updated = $(this).find('dt').text()
      const url = $(this).find('a').attr('href')
      const external = $(this).find('a').is('[target="_blank"]')
      const $item = $(newsTemplate(title, updated, url, external, $(this).find('dd')))
      $(this).replaceWith($item)
    })
  }
}