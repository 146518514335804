export const switchable = () => {
  if ($('#wrapper').hasClass('ms2022-corp-gain')) {
    $('.h01').after('<p class="mb30">みとしんの融資商品についてご案内いたします。</p>')
  }

  let reg = RegExp(/^ms2022-corp-gain-(loan_newpower|loan_quick|loan_business|loan_minorinoki|loan_businesscard|loan_superbusiness) /)
  if (reg.test($('#wrapper').attr('class'))) {
    $('.ms2022-scrollable-table').addClass('ms2022-scrollable-table--onecol')
  }

  reg = RegExp(/^ms2022-corp-gain-(loan_quick_agreement) /)
  if (reg.test($('#wrapper').attr('class'))) {
    $('.linkBtn').parent().children().wrapAll('<div class="ms2022-linkbtns ms2022-linkbtns--col2"></div>')
  }
}

export const fixed = () => {
  if ($('#wrapper').hasClass('ms2022-corp-gain')) {
    $('h3:contains("一般事業者向けローン")').attr('id', 'hGeneral')
    $('h3:contains("農業従事者向けローン")').attr('id', 'hAgri')
    $('h3:contains("創業・起業者向けローン")').attr('id', 'hEntre')
    $('h3:contains("制度融資")').attr('id', 'hSystem')
    $('h3:contains("当座貸越")').attr('id', 'hLoan')
  }

  if ($('#wrapper').hasClass('ms2022-corp-gain-loan_quick_check')) {
    if (!document.referrer.match(/^https?:\/\/(www\.mitoshin\.co\.jp|mitoshin\.stg\.iss\.shinkin\.co\.jp|mitoshin-pub\.dev\.iss\.shinkin\.co\.jp)/)) {
      location.href = '/404.html'
    }
  }
}