export default () => {
  $('.linkNav').each(function() {
    let self = $(this)
    while (self) {
      const $next = self.next('.linkNav')
      if ($next.length > 0) {
        $(this).append($next.children())
        self = $next
      } else {
        self = null
      }
    }
  })

  $('.linkNav').each(function() {
    if ($(this).children().length == 0) {
      $(this).remove()
    }
  })
  
  $.getJSON('/ms2022-assets/sitemap.json', function(sitemap) {
    const $cards = $('.linkNav').find('li')
    $cards.each(function() {
      $(this).addClass('ms2022-card')

      const href = $(this).find('a').attr('href')

      if (!$(this).hasClass('ms2022-card--noicon')) {
        iterable: for (let cat of Object.keys(sitemap)) {
          for (let child of sitemap[cat].children) {
            if ('children' in child === false) {
              if (child.url === href) {
                $(this).find('a').prepend(`<div class="ms2022-card__icon"><i class="icon-${child.icon}"></i></div>`)
                break iterable
              }
            } else {
              for (let child2 of child.children) {
                if ('children' in child2 === false) {
                  const equalUrl = child2.url === href
                  const hasIcon = 'icon' in child2
                  if (equalUrl && hasIcon) {
                    $(this).find('a').prepend(`<div class="ms2022-card__icon"><i class="icon-${child2.icon}"></i></div>`)
                    break iterable
                  }
                } else {
                  for (let child3 of child2.children) {
                    const equalUrl = child3.url === href
                    const hasIcon = 'icon' in child3
                    if (equalUrl && hasIcon) {
                      $(this).find('a').prepend(`<div class="ms2022-card__icon"><i class="icon-${child3.icon}"></i></div>`)
                      break iterable
                    }
                  }
                }
              }
            }
          }
        }

        if ($(this).find('.ms2022-card__icon').length === 0) {
          $(this).addClass('ms2022-card--noicon')
        }
      }

      // if (href.match(/^https:\/\//)) {
      //   $(this).find('a').prepend(`<div class="ms2022-card__icon"><i class="icon-${href}"></i></div>`)
      // } else {
      //   const iconName = href.replace(/^\/|\/$/g, '').replace(/\//g, '-')
      //   $(this).find('a').prepend(`<div class="ms2022-card__icon"><img src="/ms2022-assets/images/icon/${iconName}.svg"></img></div>`)
      // }

      // const href = $(this).find('a').attr('href')
      // if (href.match(/\/personal\/loan\//)) {
      //   const iconName = href.replace(/^\/|\/$/g, '').replace(/\//g, '-')
      //   $(this).find('a').prepend(`<div class="ms2022-card__icon"><i class="icon-${iconName}"></i></div>`)
      // } else {
      //   $(this).find('a').prepend('<div class="ms2022-card__icon"><img src="http://placehold.jp/80x80.png"></div>')
      // }

      const $notice = $(this).find('img[src="/personal/image/img_text01.png"]')
      $notice.replaceWith(`<p class="ms2022-card__notice">${$notice.attr('alt')}</p>`)
      if ($notice.length > 0) $(this).addClass('ms2022-card--with-notice')
    })
  })
}