export const switchable = () => {
  if ($('#wrapper').hasClass('ms2022-personal-loan-simulation')) {
    const $orginTable = $('.table01')
    const $orginTable2 = $('.table01').next()
    const $table = $('<table class="itemTable">')
    const $tableBody = $table.append('<tbody>')
    let $row, $th, $td

    $row = $('<tr>')
    $th = $orginTable.find('th:contains("月々の返済額")')
    $td = $th.eq(0).next()
    $row.append($th.eq(0))
    $row.append($td)
    $tableBody.append($row)

    $row = $('<tr>')
    $td = $th.eq(1).next()
    $row.append($th.eq(1))
    $row.append($td)
    $tableBody.append($row)
    
    $row = $('<tr>')
    $th = $orginTable.find('th:contains("ボーナス月の返済加算額")')
    $td = $th.next()
    $row.append($th)
    $row.append($td)
    $tableBody.append($row)

    $row = $('<tr>')
    $th = $orginTable.find('th:contains("年間の返済額")')
    $td = $th.next()
    $row.append($th)
    $row.append($td)
    $tableBody.append($row)

    $row = $('<tr>')
    $th = $orginTable2.find('th:contains("返済総額")')
    $td = $th.next()
    $row.append($th)
    $row.append($td)
    $tableBody.append($row)

    $table.append($tableBody)
    $orginTable.before($table)
    $orginTable.remove()
    $orginTable2.remove()

    $('.specialBox').css('background', 'transparent')
    
    const $tableRows = $('.specialBox .itemTable tr')
    $tableRows.eq(1).css('display', 'none')
    $('#method1').on('click', function() {
      $tableRows.eq(1).css('display', 'none')
      $tableRows.eq(2).css('display', 'table-row')
      $tableRows.eq(3).css('display', 'table-row')
    })
    $('#method2').on('click', function() {
      $tableRows.eq(1).css('display', 'table-row')
      $tableRows.eq(2).css('display', 'none')
      $tableRows.eq(3).css('display', 'none')
    })
    $('#method3').on('click', function() {
      $tableRows.eq(1).css('display', 'none')
      $tableRows.eq(2).css('display', 'none')
      $tableRows.eq(3).css('display', 'table-row')
    })

    $('.ms2022-scrollable-table').addClass('ms2022-scrollable-table--onecol')
  }
}

export const fixed = () => {
  if ($('#wrapper').hasClass('ms2022-personal-loan-simulation')) {
    if (!document.referrer.match(/^https?:\/\/(www\.mitoshin\.co\.jp|mitoshin\.stg\.iss\.shinkin\.co\.jp|mitoshin-pub\.dev\.iss\.shinkin\.co\.jp)/)) {
      location.href = '/404.html'
    }
  }
}