export default () => {
  $('body').append(`
    <div class="ms2022-sticky">
      <div class="ms2022-sticky__bars">
        <div class="ms2022-sticky__bar" data-sticky-index="0">
          <span>各種ログイン</span>
          個人のお客さま
        </div>
        <div class="ms2022-sticky__bar--corp" data-sticky-index="1">
          <span>各種ログイン</span>
          法人のお客さま
        </div>
      </div>
      <div class="ms2022-sticky__items">
        <div class="ms2022-sticky__item ms2022-service-login">
          <h3 class="ms2022-service-login__headline">個人のお客さま</h3>
          <div class="ms2022-service-login__body">
            <div class="ms2022-service-login__boxies">
              <div class="ms2022-service-login__box">
                <h4 class="ms2022-service-login__subhead">みとしんダイレクトバンキング</h4>
                <a class="ms2022-service-login__button ms2022-button--outline" href="/personal/pservice/direct/logon/" target="_blank">ログイン</a>
              </div>
              <div class="ms2022-service-login__box">
                <h4 class="ms2022-service-login__subhead">投信インターネットサービス</h4>
                <a class="ms2022-service-login__button ms2022-button--outline" href="/personal/apply/toushin_is/">ログイン</a>
              </div>
            </div>
          </div>
        </div>
        <div class="ms2022-sticky__item ms2022-service-login--corp">
          <h3 class="ms2022-service-login__headline">法人のお客さま</h3>
          <div class="ms2022-service-login__body">
            <div class="ms2022-service-login__boxies">
              <div class="ms2022-service-login__box">
                <h4 class="ms2022-service-login__subhead">ビジネスダイレクト</h4>
                <a class="ms2022-service-login__button ms2022-button--outline" href="/corp/cservice/direct/logon/" target="_blank">ログイン</a>
              </div>
              <div class="ms2022-service-login__box">
                <h4 class="ms2022-service-login__subhead">でんさいネットサービス</h4>
                <a class="ms2022-service-login__button ms2022-button--outline" href="/densai/" target="_blank">ログイン</a>
              </div>
              <div class="ms2022-service-login__box">
                <h4 class="ms2022-service-login__subhead">Mitoshin Big Advance</h4>
                <a class="ms2022-service-login__button ms2022-button--outline" href="https://www.mitoshin-ba.jp" target="_blank">ログイン</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    `)

    const $stickyBars = $('.ms2022-sticky__bars')
    const $stickyItems = $('.ms2022-sticky__item')
    const mobile = window.matchMedia('(max-width: 1023px)')

    // const action = window.matchMedia('(max-width: 1023px)').matches ? 'touchstart' : 'click'
    // $('[data-sticky-index]').on('click', function(event) {
    //   // event.stopPropagation()
    //   const activeIndex = $(this).attr('data-sticky-index')
    //   if (mobile.matches) {
    //     $stickyItems.eq(activeIndex).addClass('is-show')
    //   } else {
    //     $stickyItems.eq(activeIndex).addClass('is-show')
    //     $stickyBars.addClass('is-hide')
    //   }
    // })

    $('body').on('click', function(event) {
      const $stickyBar = $(event.target).closest('[data-sticky-index]')
      if ($stickyBar.length > 0) {
        const activeIndex = $stickyBar.attr('data-sticky-index')
        if (mobile.matches) {
          $stickyItems.eq(activeIndex).addClass('is-show')
        } else {
          $stickyItems.eq(activeIndex).addClass('is-show')
          $stickyBars.addClass('is-hide')
        }
      } else if ($(event.target).closest('.ms2022-sticky__item').length === 0) {
        $stickyItems.removeClass('is-show')
        $stickyBars.removeClass('is-hide')  
      }
    })
}