export default () => {
  if ($('#wrapper').hasClass('ms2022-about-disclosure')) {
    $('.h01').after('<p class="mb30">みとしんの業績や活動内容についてご案内します。</p>')
  }

  let reg = RegExp(/^ms2022-about-disclosure-(disclosure|infomation|project|relation|detail) /)
  if (reg.test($('#wrapper').attr('class'))) {
    $('.h01').each(function() {
      $(this).nextUntil('.hd02').wrapAll('<div class="ms2022-about-disclosure__contents">')
    })
    $('.hd02').each(function() {
      $(this).nextUntil('.hd02').wrapAll('<div class="ms2022-about-disclosure__contents">')
    })

    const $pageDownload = $('.hd02:contains("ページダウンロード")').next()
    
    const $linkList = $('<ul class="linkList">')
    const $honpenLinks = $pageDownload.find('a[href*="honpen"]')
    $honpenLinks.each(function() {
      const $li = $('<li>').append($(this))
      $linkList.append($li)
    })
    
    const $linkList2 = $('<ul class="linkList">')
    const $shiryoLinks = $pageDownload.find('a[href*="shiryo"]')
    $shiryoLinks.each(function() {
      const $li = $('<li>').append($(this))
      $linkList2.append($li)
    })

    $pageDownload.empty()
    $pageDownload.append('<p class="ms2022-about-disclosure__subtitle">【本編】</p>')
    $pageDownload.append($linkList)
    $pageDownload.append('<p class="ms2022-about-disclosure__subtitle">【資料編】</p>')
    $pageDownload.append($linkList2)
  }
}