export default () => {
  if ($('#wrapper').hasClass('ms2022-sitemap')) {
    $('.h03').each(function() {
      $(this).nextUntil('.h03').addBack().wrapAll('<div>')
    })
    
    $('.h04').each(function() {
      $(this).nextUntil('.h04').addBack().wrapAll('<div>')
    })
  }
}