export default () => {
  let $gnavi = $('#gnavi')
  if ($gnavi.length === 0) {
    $.get('/', function(data) {
      const html = new DOMParser().parseFromString(data, 'text/html')
      $gnavi = $(html).find('#gnavi')
      $('header').after($gnavi)
      main($gnavi)
    })
  } else {
    main($gnavi)
  }
}

function main($gnavi) {
  $gnavi.addClass('ms2022-gnav')

  /**
   * モバイル用にメニューを複製する
   */
  $(document).ready(function() {
    const $searchArea = $('header .searchArea').clone()
    $searchArea.find('button').empty()
    $searchArea.find('button').append('<i class="icon-search"></i>')
    const $navButtons = $('.ms2022-nav-buttons').clone()
    $navButtons.children().last().remove()
    $gnavi.prepend($navButtons)
    $gnavi.prepend($searchArea)
  })
  
  const $menuBtn = $(`
    <button class="ms2022-icon-button">
      <div class="ms2022-icon-button__bars">
        <div></div>
        <div></div>
        <div></div>
      </div>
      <span class="ms2022-icon-button__text">メニュー</span>
    </button>
  `)
  .appendTo($('header > .inner'))

  $.getJSON('/ms2022-assets/gnav.json', function(gnav) {
    $.getJSON('/ms2022-assets/sitemap.json', function(sitemap) {
      /**
       * グローバルナビゲーションの整形
       */
      if (location.pathname === '/' || location.pathname.match(/\/(personal|_kinri|_tesuryo)\//)) {
        const newMenu = getNewMenu(gnav.personal, sitemap.personal)
        createNav('#gnavi ul li.gnavi01', newMenu, loginTemplate('personal'))
      } else if ($('#wrapper').is('[class^="ms2022-corp"]')) {
        const newMenu = getNewMenu(gnav.corp, sitemap.corp)
        createNav('#gnavi ul li.gnavi02', newMenu, loginTemplate('corp'))
      } else if ($('#wrapper').is('[class^="ms2022-about"]')) {
        const newMenu = getNewMenu(gnav.about, sitemap.about)
        createNav('#gnavi ul li.gnavi03', newMenu)
      } else {
        $gnavi.addClass('ms2022-gnav--other')
      }
  
      $('span:contains("個人のお客様")').text('個人のお客さま')
      $('span:contains("法人・事業主のお客様")').text('法人・事業主のお客さま')
  
      if (document.readyState === 'complete') {
        setGnavBarHeight()
      } else {
        $(window).on('load', setGnavBarHeight)
      }
  
      /**
       * ナビゲーション共通イベント
       */
      $menuBtn.on('click', () => {
        if (!$menuBtn.hasClass('is-show')) {
          $gnavi.addClass('is-show')
          $menuBtn.addClass('is-show')
          $menuBtn.find('.ms2022-icon-button__text').text('閉じる')
        } else {
          $gnavi.removeClass('is-show')
          $gnavi.find('.is-show').each(function() {
            $(this).removeClass('is-show')
            $(this).next().css('height', 0)
          })
          $menuBtn.removeClass('is-show')
          $menuBtn.find('.ms2022-icon-button__text').text('メニュー')
        }
      })
  
      $('body').on('click', event => {
        const notExistsSearchButton = $(event.target).closest($menuBtn).length === 0
        const notExistsSearchArea = $(event.target).closest($gnavi).length === 0
        if (notExistsSearchButton && notExistsSearchArea) {
          $gnavi.removeClass('is-show')
          $gnavi.find('.is-show').each(function() {
            $(this).removeClass('is-show')
            $(this).next().css('height', 0)
          })
          $menuBtn.removeClass('is-show')
          $menuBtn.find('.ms2022-icon-button__text').text('メニュー')
        }
      })
      
      /**
       * ナビゲーションモバイル用イベント
       */
      const mq = window.matchMedia('(max-width: 1200px)')
      
      $('.ms2022-category__bar').on('click', function(event) {
        if (!mq.matches) return
        event.preventDefault()
        if (!$(this).hasClass('is-show')) {
          $(this).addClass('is-show')
          const height = $(this).next()[0].scrollHeight
          $(this).next().animate({ height }, 200)
        } else {
          $(this).removeClass('is-show')
          $(this).next().animate({ height: 0 }, 200)
        }
      })
  
      /**
       * ナビゲーションラップトップ用イベント
       */
      let stackEvent
  
      $('.ms2022-category__bar').on('mouseenter', function() {
        if (mq.matches) return
        if (!$(this).hasClass('is-show')) {
          stackEvent = setTimeout(() => $(this).addClass('is-show'), 200)
        }
      })
  
      $('.ms2022-category').on('mouseleave', function() {
        if (mq.matches) return
        clearTimeout(stackEvent)
        const $bar = $(this).find('.ms2022-category__bar')
        if ($bar.hasClass('is-show')) $bar.removeClass('is-show')
      })
  
      mq.addEventListener('change', mqList => {
        if (mqList.matches) return
        setTimeout(setGnavBarHeight, 100)
        $gnavi.removeClass('is-show')
        $('.ms2022-category__bar').each(function() {
          $(this).removeClass('is-show')
          $(this).attr('style', '')
        })
        $('.ms2022-category__container').each(function() {
          $(this).attr('style', '')
        })
      })
    })
  })
}

function createNav(appendTarget, navs, login = '') {
  const pathname = location.pathname
  const dom = `
    <div class="ms2022-gnav__sub-category">
      ${navs.map(nav => {
      return `
        <div class="ms2022-category">
          <div class="ms2022-category__bar ${pathname.match(nav.url) ? 'is-current' : ''}">
            ${nav.url
              ? `<a href="${nav.url}">${nav.name}</a>`
              : `<div>${nav.name}</div>`
            }
          </div>
          <div class="ms2022-category__container">
            ${login}
            ${navChildrenTemplate(nav)}
          </div>
        </div>`
      }).join('')}
    </div>`
  $(appendTarget).after(dom).addClass('is-current')
}

function navChildrenTemplate(nav) {
  return `
    <div class="ms2022-category__menus">
      ${'url' in nav
        ? `<a class="ms2022-category__menu--cattop ms2022-button--outline" href="${nav.url}">${nav.name}トップ</a>`
        : ''
      }
      ${nav.children.map(child => {
        const blank = child.external ? 'target="_blank"' : ''
        return `<a class="ms2022-category__menu ms2022-button--outline" href="${child.url}" ${blank}>${child.name}</a>`
      }).join('')}
    </div>`
}

function loginTemplate(category) {
  return `
    <div class="ms2022-category__login ms2022-service-login--outline">
      <h3 class="ms2022-service-login__headline">各種ログイン</h3>
      <div class="ms2022-service-login__body">
        <div class="ms2022-service-login__boxies">
          ${category === 'personal' ? `
            <div class="ms2022-service-login__box">
              <h4 class="ms2022-service-login__subhead">みとしんダイレクトバンキング</h4>
              <a class="ms2022-service-login__button ms2022-button--outline" href="/personal/pservice/direct/logon/" target="_blank">ログイン</a>
            </div>
            <div class="ms2022-service-login__box">
              <h4 class="ms2022-service-login__subhead">投信インターネットサービス</h4>
              <a class="ms2022-service-login__button ms2022-button--outline" href="/personal/apply/toushin_is/">ログイン</a>
            </div>` : `
            <div class="ms2022-service-login__box">
              <h4 class="ms2022-service-login__subhead">ビジネスダイレクト</h4>
              <a class="ms2022-service-login__button ms2022-button--outline" href="/corp/cservice/direct/logon/" target="_blank">ログイン</a>
            </div>
            <div class="ms2022-service-login__box">
              <h4 class="ms2022-service-login__subhead">でんさいネットサービス</h4>
              <a class="ms2022-service-login__button ms2022-button--outline" href="/densai/" target="_blank">ログイン</a>
            </div>
            <div class="ms2022-service-login__box">
              <h4 class="ms2022-service-login__subhead">Mitoshin Big Advance</h4>
              <a class="ms2022-service-login__button ms2022-button--outline" href="https://www.mitoshin-ba.jp" target="_blank">ログイン</a>
            </div>`}
        </div>
      </div>
    </div>`
}

function getNewMenu(gnav, sitemap) {
  const names = sitemap.children.map(child => child.name)
  return gnav.map(item => {
    const index = names.findIndex((name) => name === item)
    return index > -1 ? sitemap.children[index] : item
  })
}

function setGnavBarHeight() {
  let height

  if ($('.ms2022-gnav__sub-category').length > 0) {
    height = $('.ms2022-gnav__sub-category')[0].clientHeight
  } else {
    height = 0
  }

  $(':root').css('--gnav-bar-height', `${height}px`)
}