import Swiper, { Autoplay, Pagination } from 'swiper'

export const switchable = () => {
  if ($('#wrapper').is('[class^="ms2022-corp"]')) {
    $(':root').get(0).style.setProperty('--primary-color', '#1d2087')
    $(':root').get(0).style.setProperty('--secondary-color', '#f2f3ff')
  }

  if ($('#wrapper').hasClass('ms2022-corp-run')) {
    $('.h01').after('<p class="mb30">みとしんの預金商品についてご案内いたします。</p>')
  }

  let reg = RegExp(/^ms2022-corp-run-touza /)
  if (reg.test($('#wrapper').attr('class'))) {
    $('.ms2022-scrollable-table').addClass('ms2022-scrollable-table--onecol')
  }

  if ($('#wrapper').hasClass('ms2022-corp-prepare')) {
    $('.h01').after('<p class="mb30">みとしんの保険商品についてご案内いたします。</p>')
  }

  if ($('#wrapper').hasClass('ms2022-corp-support')) {
    $('.h01').after('<p class="mb30">ビジネスフェアなどのビジネス支援をご案内いたします。</p>')
  }

  if ($('#wrapper').hasClass('ms2022-corp-cservice')) {
    $('.h01').after('<p class="mb30">給与振込契約など様々な便利なサービスでお客様をサポートいたします。</p>')
  }

  if ($('#wrapper').hasClass('ms2022-corp-cashless')) {
    $('.h01').after('<p class="mb30">キャッシュレス決済サービスの導入をサポートいたします。</p>')
  }
}

export const fixed = () => {
  if ($('#wrapper').hasClass('ms2022-corp')) {
    new Swiper('.ms2022-corp__slider .swiper', {
      modules: [Autoplay, Pagination],
      slidesPerView: 1,
      speed: 1000,
      autoplay: {
        delay: 8000
      },
      loop: true,
      pagination: {
        el: '.ms2022-corp__slider .swiper-pagination',
        type: 'bullets',
        clickable: true
      },
    })

    // let $section = $('<section>')

    // $('#main').append(`
    //   <div class="ms2022-corp__keyvisual">
    //     <picture>
    //       <source media="(min-width: 1200px)" srcset="/ms2022-assets/images/corp/hero.lg.jpg">
    //       <img src="/ms2022-assets/images/corp/hero.jpg">
    //     </picture>
    //   </div>
    // `)
    // $section.append(`
    //   <div class="ms2022-service-login--corp ms2022-container">
    //     <h3 class="ms2022-service-login__headline">法人のお客様</h3>
    //     <div class="ms2022-service-login__body ms2022-container">
    //       <div class="ms2022-service-login__boxies">
    //         <div class="ms2022-service-login__box">
    //           <h4 class="ms2022-service-login__subhead">ビジネスダイレクト</h4>
    //           <a class="ms2022-service-login__button ms2022-button--outline" href="/corp/cservice/direct/logon/" target="_blank">ログイン</a>
    //         </div>
    //         <div class="ms2022-service-login__box">
    //           <h4 class="ms2022-service-login__subhead">でんさいネットサービス</h4>
    //           <a class="ms2022-service-login__button ms2022-button--outline" href="/densai/" target="_blank">ログイン</a>
    //         </div>
    //         <div class="ms2022-service-login__box">
    //           <h4 class="ms2022-service-login__subhead">Mitoshin Big Advance</h4>
    //           <a class="ms2022-service-login__button ms2022-button--outline" href="https://www.mitoshin-ba.jp" target="_blank">ログイン</a>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // `)
    // $section.append(`
    //   <div class="ms2022-caution">
    //     <div class="ms2022-container">
    //       <i class="ms2022-caution__icon fa-solid fa-circle-exclamation"></i>
    //       <a href="/notice/index.shtml">お客様に安全にお取引いただくため、金融犯罪にご注意ください。<i class="fa-solid fa-arrow-up-right-from-square"></i></a>
    //     </div>
    //   </div>
    // `)
    // $('#main').append($section)
    
    // $.getJSON('/ms2022-assets/sitemap.json', function(sitemap) {
    //   $section = $('<section>')
    //   $section.append('<h2 class="ms2022-corp__subhead">目的から商品を探す</h2>')
    //   let $springBoard = $('<div class="ms2022-spring-board ms2022-container">')
    //   $.each(sitemap.corp.children, function(index, value) {
    //     const $item = $(`
    //       <a href="${value.url}" class="ms2022-spring-board__item">
    //         <i class="ms2022-spring-board__icon icon-${value.categoryIcon}"></i>
    //         <p class="ms2022-spring-board__title">${value.name}</p>
    //       </a>
    //     `)
    //     $springBoard.append($item)
    //   })
    //   $section.append($springBoard)
    //   $('#main').append($section)

    //   $section = $('<section>')
    //   $section.append('<h2 class="ms2022-corp__subhead">お手続きに関する情報</h2>')
    //   $springBoard = $('<div class="ms2022-spring-board ms2022-container">')
    //   $.each(sitemap.other.children, function(index, value) {
    //     const $item = $(`
    //       <a href="${value.url}" class="ms2022-spring-board__item">
    //         <i class="ms2022-spring-board__icon icon-${value.categoryIcon}"></i>
    //         <p class="ms2022-spring-board__title">${value.name}</p>
    //       </a>
    //     `)
    //     $springBoard.append($item)
    //   })
    //   $section.append($springBoard)
    //   $('#main').append($section)
    // })
  }
}