export default () => {
  let suffix

  if (location.pathname === '/') {
    suffix = '-home'
  } else {
    suffix = location.pathname.replace(/\//g, '-').replace(/-$/, '').replace(/-index.*\.s?html$/, '')
  }

  $('body').addClass('ms2022')
  $('#wrapper').addClass('ms2022' + suffix)
}