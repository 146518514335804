export default () => {
  const isMobile = window.matchMedia('(max-width: 1119px)')

  if (isMobile.matches) {
    $(window).ready(function() {
      $('.itemTable table').each(function() {
        setParentWidth($(this))
      })
    })
  }
  
  $(window).resize(function() {
    $('.itemTable table').each(function() {
      if (!isMobile.matches) {
        $(this).css('width', '')
        return
      }
      setParentWidth($(this))
    })
  })
}

function setParentWidth($el) {
  $el.css('display', 'none')
  setTimeout(() => {
    $el.css({
      display: '',
      width: $el.parent().width()
    })
  }, 0)
}