export default () => {
  if ($('#wrapper').hasClass('ms2022-personal-save')) {
    $('.h01').after('<p class="mb30">みとしんの預金商品についてご案内いたします。</p>')
  }

  if ($('#wrapper').hasClass('ms2022-personal-save-cancel')) {
    $('.itemTable').wrap('<div class="ms2022-scrollable-table">')
  }
  
  let reg = RegExp(/^ms2022-personal-save-(liquidity_ordinary|loan_quick_check|liquidity_nointerest|liquidity_short|liquidity_saving|periodic_super|periodic_large|periodic_specify|periodic_change|periodic_reserve|periodic_super_reserve) /)
  if (reg.test($('#wrapper').attr('class'))) {
    $('.ms2022-scrollable-table').addClass('ms2022-scrollable-table--onecol')
    $('.ms2022-scrollable-table').find('.ms2022-scrollable-table').removeClass('ms2022-scrollable-table--onecol')
  }

  if ($('#wrapper').hasClass('ms2022-personal-prepare')) {
    $('.h01').after('<p class="mb30">みとしんの保険商品についてご案内いたします。</p>')
  }
}