export const switchable = () => {
  if ($('#wrapper').is('[class^="ms2022-about"]')) {
    $(':root').get(0).style.setProperty('--primary-color', '#ed7c9b')
    $(':root').get(0).style.setProperty('--secondary-color', '#ffe2ea')
  }

  if ($('#wrapper').hasClass('ms2022-about-company')) {
    $('.h01').after('<p class="mb30">水戸信用金庫の概要についてご案内します。</p>')
  }

  let reg = RegExp(/^ms2022-about-company-(history|outline) /)
  if (reg.test($('#wrapper').attr('class'))) {
    $('.ms2022-scrollable-table').addClass('ms2022-scrollable-table--onecol')
  }

  if ($('#wrapper').hasClass('ms2022-about-area')) {
    $('.h01').after('<p class="mb30">みとしんの地域貢献、教育支援、スポーツ振興への取り組みについてご案内します。</p>')
  }

  if ($('#wrapper').hasClass('ms2022-about-circle')) {
    $('.h01').after('<p class="mb30">みとしんの各種サークルについてご案内します。</p>')
  }

  if ($('#wrapper').hasClass('ms2022-about-kanren')) {
    $('.h01').after('<p class="mb30">みとしんの関連会社についてご案内します。</p>')
  }

  reg = RegExp(/^ms2022-about-kanren-(lease|souken) /)
  if (reg.test($('#wrapper').attr('class'))) {
    $('.ms2022-scrollable-table').addClass('ms2022-scrollable-table--onecol')
  }
}

export const fixed = () => {
  if ($('#wrapper').hasClass('ms2022-about')) {
    // let $section = $('<section>')

    // $('#main').append(`
    //   <div class="ms2022-about__keyvisual">
    //     <picture>
    //       <source media="(min-width: 1200px)" srcset="/ms2022-assets/images/about/hero.lg.jpg">
    //       <img src="/ms2022-assets/images/about/hero.jpg">
    //     </picture>
    //   </div>
    // `)
    
    // $section = $('<section>')
    // $section.append('<h2 class="ms2022-about__subhead">コンテンツ</h2>')
    // const $springBoard = $('<div class="ms2022-spring-board ms2022-container">')
    // $.getJSON('/ms2022-assets/sitemap.json', function(sitemap) {
    //   $.each(sitemap.about.children, function(index, value) {
    //     const $item = $(`
    //       <a href="${value.url}" class="ms2022-spring-board__item">
    //         <i class="ms2022-spring-board__icon icon-${value.categoryIcon}"></i>
    //         <p class="ms2022-spring-board__title">${value.name}</p>
    //       </a>
    //     `)
    //     $springBoard.append($item)
    //   })
    //   $section.append($springBoard)
    //   $('#main').append($section)
    // })
  }
}