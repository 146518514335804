export default () => {
  $('.columnArea').each(function() {
    if ($(this).find('.inquiryArea').length) {
      $(this).addClass('ms2022-contact')
    }
  })
  
  const $inquiry = $('.inquiryArea')
  $inquiry.children().each(function() {
    if ($(this).text().match(/お近くの店舗/)) {
      const $anchor = $(this).find('a')
      $(this).find('strong').html($anchor.text('お近くの店舗検索'))
      $anchor.replaceWith(`<span>${$anchor.text()}</span>`)
      $(this).addClass('ms2022-search-store')
      $(this).prepend('<div class="ms2022-search-store__icon"><i class="icon-location"></i></div>')
      $(this).find('.linkBtn').remove()
      $(this).replaceWith(`<a class="ms2022-search-store" href="${$anchor.attr('href')}">` + $(this).html() + '</a>')
    }
    
    else if ($(this).text().match(/店舗検索/)) {
      const $anchor = $(this).find('a')
      const $strong = $('<strong>').append($anchor)
      $anchor.text('お近くの店舗検索')
      $anchor.replaceWith(`<span>${$anchor.text()}</span>`)
      $(this).prepend('<div class="ms2022-search-store__icon"><i class="icon-location"></i></div>')
      $(this).find('.linkBtn').replaceWith($strong)
      $(this).replaceWith(`<a class="ms2022-search-store" href="${$anchor.attr('href')}">` + $(this).html() + '</a>')
    }
  
    else {
      const faxNum = $(this).text().match(/FAX:(\d+[-|‐]\d+[-|‐]\d+)/)
      const $openTime = $(this).find('p:contains("受付時間")')
      let $tel, telNum

      if ($(this).text().match(/TEL:/)) {
        telNum = $(this).text().match(/TEL:(\d+[-|‐]\d+[-|‐]\d+)/)
        $tel = $(this).find('strong:contains("TEL:")')
        $tel.html(`TEL：<span>${telNum[1]}</span>`)
        $tel.parent().addClass('ms2022-call-center__tel')
      } else if ($(this).text().match(/フリーダイヤル/)) {
        telNum = $(this).text().match(/(\d+[-|‐]\d+[-|‐]\d+)/)
        $(this).find('p:contains("TEL(フリーダイヤル)：")').remove()
        $tel = $(this).find(`strong:contains("${telNum[1]}")`)
        $tel.html(`TEL（フリーダイヤル）：<span>${telNum[1]}</span>`)
        $tel.parent().addClass('ms2022-call-center__tel')
      }

      if (faxNum) {
        const $fax = $('<p class="ms2022-call-center__fax"></div>')
        $fax.append(`FAX：<span>${faxNum[1]}</span>`)
        $tel.parent().after($fax)
      }

      if (!$openTime.text().match(/土・日・祝休日/)) {
        const $holiday = $(this).find('p:contains("土・日・祝休日")')
        $openTime.append($holiday.text())
        $holiday.remove()
      }

      $(this).replaceWith(`<a class="ms2022-call-center" href="tel:${telNum[1]}">${$(this).html()}</a>`)
    }
  })
}