export default () => {
  const observer = new MutationObserver((mutations, observer) => {
    for (const mutation of mutations) {
      for (let node of mutation.addedNodes) {
        if (!(node instanceof HTMLElement)) continue
        if (node.matches('[id="topcontrol"]')) node.innerHTML = '<i class="icon-arrow"></i>ページの先頭へ'
      }
    }
  })
  observer.observe(document.body, { attributes: false, childList: true, subtree: false })
}