export const switchable = () => {
  if ($('#wrapper').hasClass('ms2022-personal-loan')) {
    $('.h01').after('<p class="mb30">みとしんの融資商品についてご案内いたします。</p>')
  }

  // if ($('#wrapper').hasClass('ms2022-personal-loan-mitoshin_cash')) {
  //   $('.h01').nextUntil('.ms2022-scrollable-table').wrapAll('<div class="ms2022-cash"></div>')
  //   const $li = $('.ms2022-cash li')
  //   const $anchor = $li.eq(0).find('> a')
    
  //   $li.each(function() {
  //     const text = $(this).text().split(/\s+/)
  //     $(this).empty()
  //     text.forEach(str => {
  //       const s = str.replace(/\((.*)\)/, '$1：')
  //       $(this).append(`<p>${s}</p>`)
  //     })
  //   })
    
  //   const $notice = $('.ms2022-cash .number > p')
  //   $notice.text($notice.text().replace(/\s+/, ''))
  //   $li.eq(0).find('p:contains("web完結型カードローンの詳細はこちら")').replaceWith($anchor)
  //   $li.eq(0).append($notice)
  
  //   const number = $('.ms2022-cash .borderSec .emSec').text().replace(/(\d+-\d+-\d+) (.*)/, '<span class="ms2022-cash__number">$1</span>$2')
  //   $('.ms2022-cash .borderSec .emSec').html(number)
  
  //   const $borderSec = $('.ms2022-cash .borderSec')
  //   $borderSec.html($borderSec.html().replace(/^\s+/, ''))
  // }

  let reg = RegExp(/^ms2022-personal-loan-(card3|carlife3|education3|housing_change3|personal3|reform3|shokuiki) /)
  if (reg.test($('#wrapper').attr('class'))) {
    $('.linkBtn').parent().children().wrapAll('<div class="ms2022-linkbtns ms2022-linkbtns--col2"></div>')
  }
  
  if ($('#wrapper').hasClass('ms2022-personal-loan-judgement')) {
    $('.linkBtn').next('.linkBtn').addBack().wrapAll('<div class="ms2022-linkbtns">')
  }

  if ($('#wrapper').hasClass('ms2022-personal-save')) {
    $('.linkBtn').next('.linkBtn').addBack().wrapAll('<div class="ms2022-linkbtns">')
  }
  
  reg = RegExp(/^ms2022-personal-loan-(housing_choice|housing_change|housing_reform|housing_reform|reform|reform_eco|carlife|education|welfare|child|free_smile|free_quick|free_pocket|seniorlife|personal|card|mitoshin_cash|mitoshin_silver_cash|pocketableZ) /)
  if (reg.test($('#wrapper').attr('class'))) {
    $('.ms2022-scrollable-table').addClass('ms2022-scrollable-table--onecol')
    $('.ms2022-scrollable-table').find('.ms2022-scrollable-table').removeClass('ms2022-scrollable-table--onecol')
  }

  reg = RegExp(/^ms2022-personal-loan-(free_quick_agreement|free_smile_agreement) /)
  if (reg.test($('#wrapper').attr('class'))) {
    $('.linkBtn').parent().children().wrapAll('<div class="ms2022-linkbtns ms2022-linkbtns--col2"></div>')
  }
  
  if (
    $('#wrapper').hasClass('ms2022-personal-loan-carlife') ||
    $('#wrapper').hasClass('ms2022-personal-loan-education') ||
    $('#wrapper').hasClass('ms2022-personal-loan-mitoshin_cash')
  ) {
    const $anchorButton = $('.ms2022-lp-sticky__button, .ms2022-lp-navigation__button--important, .ms2022-lp-castle-accepting__button, .ms2022-lp-castle-points__button')
    const $permissions = $('input[name="permission"]')
    const $permissionButton = $('.ms2022-lp-permission__button')

    $anchorButton.click(() => {
      const top = $('.ms2022-lp-permission').offset().top
      $('html,body').animate({ scrollTop: top }, 400);
    })

    $permissions.change(() => {
      const result = $permissions.filter(function() {
        return $(this).prop('checked')
      })
      if ($permissions.length === result.length) {
        $permissionButton.removeClass('is-disabled')
      } else {
        $permissionButton.addClass('is-disabled')
      }
    })
  }
}

export const fixed = () => {
  if ($('#wrapper').hasClass('ms2022-personal-loan-free_smile_check')) {
    if (!document.referrer.match(/^https?:\/\/(www\.mitoshin\.co\.jp|mitoshin\.stg\.iss\.shinkin\.co\.jp|mitoshin-pub\.dev\.iss\.shinkin\.co\.jp)/)) {
      location.href = '/404.html'
    }
  }

  if ($('#wrapper').hasClass('ms2022-personal-loan-free_quick_check')) {
    if (!document.referrer.match(/^https?:\/\/(www\.mitoshin\.co\.jp|mitoshin\.stg\.iss\.shinkin\.co\.jp|mitoshin-pub\.dev\.iss\.shinkin\.co\.jp)/)) {
      location.href = '/404.html'
    }
  }
}