export default () => {
  const $header = $('header > .inner')
  let $searchArea = $header.find('.rightArea .topBlock .searchArea')
  const isDesktop = window.matchMedia('(min-width: 768px)').matches

  const $buttons = $('<div class="ms2022-nav-buttons"></div>')
  $buttons.append($(`
    <a href="/atm/" class="ms2022-nav-buttons__item ms2022-icon-button">
      <i class="ms2022-icon-button__icon icon-location"></i>
      <span class="ms2022-icon-button__text">店舗・ATM</span>
    </a>
  `))
  $buttons.append($(`
    <a href="/others/faq/" class="ms2022-nav-buttons__item ms2022-icon-button">
      <i class="ms2022-icon-button__icon icon-question"></i>
      <span class="ms2022-icon-button__text">よくある質問</span>
    </a>
  `))
  const $searchBtn = $(`
    <button class="ms2022-nav-buttons__item ms2022-icon-button">
      <i class="ms2022-icon-button__icon icon-search"></i>
      <span class="ms2022-icon-button__text">検索</span>
    </button>
  `)
  $buttons.append($searchBtn)
  $header.append($buttons)

  if ($searchArea.length === 0) {
    $header.find('.rightArea .topBlock').append('<div class="searchArea">')
    $searchArea = $header.find('.rightArea .topBlock .searchArea')
  }
  $searchArea.append(`
    <form action="https://www.google.co.jp/cse" name="cse-search-box"> 
      <input value="1c1ab27b9d7445f16" name="cx" type="hidden"> 
      <input value="UTF-8" name="ie" type="hidden"> 
      <input name="q" type="text" placeholder="キーワードを入力"> 
    </form>
  `)
  const $closeBtn = $('<button type="button"><i class="fas fa-times"></i></button>')
  $searchArea.find('form').append($closeBtn)

  $searchBtn.on('click', () => {
    $searchArea.addClass('is-show')
    if (isDesktop) $searchArea.css('width', $searchArea[0].scrollWidth + 'px')
  })

  $closeBtn.on('click', () => {
    $searchArea.removeClass('is-show')
    if (isDesktop) $searchArea.css('width', '0')
  })

  $(window).on('click', event => {
    if (isDesktop) return
    const notExistsSearchButton = $(event.target).closest($searchBtn).length === 0
    const notExistsSearchArea = $(event.target).closest('.searchArea').length === 0
    if (notExistsSearchButton && notExistsSearchArea) {
      $searchArea.removeClass('is-show')
    }
  })
}