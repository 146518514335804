import Swiper, { Autoplay, Pagination, Navigation } from 'swiper'
import newsTemplate, { importantTemplate } from '/src/js/components/news-template'

function initNews() {
  const $latestNews = $('.ms2022-news-list--latest > dl').children().map(function() {
    if ($(this).prop('tagName') === 'DT') {
      return initNewsItem($(this))
    }
  }).toArray().join('')
  $('.ms2022-news-list--latest').html($latestNews)

  const $infoNews = $('.ms2022-news-list--info > dl').children().map(function() {
    if ($(this).prop('tagName') === 'DT') {
      return initNewsItem($(this))
    }
  }).toArray().join('')
  $('.ms2022-news-list--info').html($infoNews)

  const $importantNews = $('.ms2022-news-list--important > ul').children().map(function() {
    const $anchor = $(this).find('a')
    const title = $anchor.text()
    const url = $anchor.attr('href')
    const external = $anchor.is('[target="_blank"]')
    return importantTemplate(title, url, external)
  }).toArray().join('')
  $('.ms2022-news-list--important').html($importantNews);

  const $tabContainer = $('.ms2022-news-tab')
  const $tabButton = $tabContainer.find('[data-tab-index]')
  $tabButton.on('click', ({ target }) => {
    const activeIndex = $(target).data('tab-index')
    $tabContainer.attr('data-tab-active-index', activeIndex)
  })
}

function initNewsItem($el) {
  const $anchor = $el.next('dd').find('a')
  const title = $anchor.text()
  const updated = $el.text()
  const url = $anchor.attr('href')
  const external = $anchor.is('[target="_blank"]')
  return newsTemplate(title, updated, url, external, $el.next('dd'))
}

function initBanner() {
  const $banner = $('.ms2022-banners .banner')
  const $slides = $banner.children().map(function() {
    return `
      <div class="swiper-slide">
        ${$(this).prop('outerHTML')}
      </div>`
  }).toArray().join('')
  $banner.replaceWith(`
    <div class="ms2022-container">
      <div class="swiper">
        <div class="swiper-wrapper">
          ${$slides}
        </div>
        <div class="ms2022-banners__controller">
          <div class="swiper-button-prev"></div>
          <div class="swiper-pagination"></div>
          <div class="swiper-button-next"></div>
        </div>
      </div>
    </div>
  `)

  new Swiper('.ms2022-banners .swiper', {
    modules: [Pagination, Navigation],
    slidesPerView: 2,
    spaceBetween: 16,
    autoHeight: true,
    loop: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    breakpoints: {
      1200: {
        slidesPerView: 6,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      }
    }
  })
}

export const fixed = () => {
  if ($('#wrapper').hasClass('ms2022-home')) {
    new Swiper('.ms2022-home__main-slider .swiper', {
      modules: [Autoplay, Pagination],
      slidesPerView: 1,
      speed: 1000,
      autoplay: {
        delay: 8000
      },
      loop: true,
      pagination: {
        el: '.ms2022-home__main-slider .swiper-pagination',
        type: 'bullets',
        clickable: true
      },
    })

    const $banner = $('.ms2022-campaign li')
    const $slides = $banner.children().map(function() {
      return `
        <div class="swiper-slide">
          ${$(this).prop('outerHTML')}
        </div>`
    }).toArray().join('')
    $('.ms2022-campaign .ms2022-container').html(`
      <div class="ms2022-campaign__swiper-container">
        <div class="swiper">
          <div class="swiper-wrapper">
            ${$slides}
          </div>
          <div class="swiper-pagination"></div>
        </div>
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>
      </div>
    `)
    
    new Swiper('.ms2022-campaign .swiper', {
      modules: [Pagination, Navigation],
      slidesPerView: 2,
      spaceBetween: 16,
      autoHeight: true,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      breakpoints: {
        1200: {
          slidesPerView: 3,
          spaceBetween: 40,
          navigation: {
            nextEl: '.ms2022-campaign .swiper-button-next',
            prevEl: '.ms2022-campaign .swiper-button-prev',
          },
        }
      }
    })

    initNews()
    initBanner()
  }
}