export default () => {
  const $relationalButtons = $('.h01.visual + .alR')
  $relationalButtons.addClass('ms2022-relational-buttons')
  
  const $imageButtons = $relationalButtons.find('img[src*="bt_"]')
  if ($imageButtons.length > 0) {
    $imageButtons.each(function() {
      const alt = $(this).attr('alt')
      const $newNode = $('<div class="ms2022-button">').text(alt)
      $(this).replaceWith($newNode)
    })
  } else {
    const $textButtons = $relationalButtons.children()
    $textButtons.each(function() {
      const $newNode = $('<div class="ms2022-button">').text($(this).text())
      $(this).empty()
      $(this).append($newNode)
    })
  }
}