import Swiper, { Autoplay, Pagination } from 'swiper'

export default () => {  
  if ($('#wrapper').hasClass('ms2022-personal-apply')) {
    $('.h01').after('<p class="mb30">みとしんの資産運用にご活用いただける商品をご案内いたします。</p>')
  }

  if ($('#wrapper').hasClass('ms2022-personal-apply-investment')) {
    // const $elements = $('.hd02:contains("投資信託について")').nextUntil('.noteBox')
    // const $buttons = $elements.find('[class*="Btn"]')
    // const $linkBtns = $('<div class="ms2022-linkbtns">').append($buttons)
    // $('.hd02:contains("投資信託について")').after($linkBtns)
    // $elements.remove()

    $('.ms2022-investment-navigation__nav').click(function() {
      const href = $(this).attr('href')
      const top = $(href).offset().top
      $('html,body').animate({ scrollTop: top }, 400)
    })

    new Swiper('.ms2022-investment-banner .swiper', {
      modules: [Autoplay, Pagination],
      slidesPerView: 1,
      spaceBetween: 16,
      speed: 1000,
      autoplay: {
        delay: 5000
      },
      autoHeight: true,
      loop: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true
      },
      grabCursor: true
    })
  }

  if ($('#wrapper').hasClass('ms2022-personal-apply-toushin_is')) {
    $('.blankBtn').wrapAll('<div class="ms2022-linkbtns">')
    $('table').wrap('<div class="ms2022-scrollable-table">')
    $('.h01').next().after(`
      <form action="https://www.shinkin-toushin-internet.jp/Login/0/login/ipan_web/hyoji" method="POST" target="_blank">
        <div class="ms2022-linkbtns mt30">
          <button class="blankBtn">
            <input type="hidden" name="bankcd" value="01240">
            投信インターネット ログイン
          </button>
        </div>
      </form>
    `)
  }

  if ($('#wrapper').hasClass('ms2022-personal-apply-flow')) {
    $('.linkBtn').parent().children().wrapAll('<div class="ms2022-linkbtns"></div>')
  }

  if ($('#wrapper').hasClass('ms2022-personal-apply-fund')) {
    $('.linkBtn').parent().children().wrapAll('<div class="ms2022-linkbtns"></div>')

    const $ancorLinkNav = $('.ancorLinkNav')
    $ancorLinkNav.first().addClass('ms2022-linkbtns')
    $ancorLinkNav.first().removeClass('ancorLinkNav')
    $ancorLinkNav.each(function() {
      $(this).children().addClass('linkBtn')
      $ancorLinkNav.first().append($(this).children())
    })
    $('.ancorLinkNav').remove()

    $('.fundTable').wrap('<div class="ms2022-scrollable-table">')
  }

  if ($('#wrapper').hasClass('ms2022-personal-apply-merit')) {
    $('.linkBtn').parent().children().wrapAll('<div class="ms2022-linkbtns"></div>')
  }

  if ($('#wrapper').hasClass('ms2022-personal-apply-structure')) {
    $('.linkBtn').parent().children().wrapAll('<div class="ms2022-linkbtns"></div>')
  }
}