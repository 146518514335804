import "image-map-resizer/js/imageMapResizer.min"

export default () => {
  if ($('#wrapper').hasClass('ms2022-atm')) {
    $(document).ready(function() {
      $('map').imageMapResize()
    })

    /**
     * 遷移先からブラウザバッグすると地図画像が表示されない問題への対処
     * iOS Safari
     */
    window.addEventListener('pageshow', event => {
      if (event.persisted) {
        $('img#map').attr('src', '/atm/image/map.png')
      }
    })

    let $linkWrapper = $('<div class="ms2022-linkbtns">')
    let $areaSearchs = $('.h03:contains("茨城県")').nextUntil('.h03', '.areaSearch')
    let $linkBtns = $areaSearchs.find('.linkBtn')
    $linkWrapper.append($linkBtns)
    $areaSearchs.remove()
    $('.h03:contains("茨城県")').after($linkWrapper)
    
    $linkWrapper = $('<div class="ms2022-linkbtns">')
    $areaSearchs = $('.h03:contains("その他")').next('.areaSearch')
    $linkBtns = $areaSearchs.find('.linkBtn')
    $linkWrapper.append($linkBtns)
    $areaSearchs.remove()
    $('.h03:contains("その他")').after($linkWrapper)
  }
  
  if ($('#wrapper').hasClass('ms2022-atm-lawson')) {
    const $table = $('table:not(.itemTable)')
    $table.addClass('itemTable')
    $table.wrap('<div class="ms2022-scrollable-table"></div>')
  }
}