export default () => {
  if ($('#wrapper').hasClass('ms2022-personal-pservice')) {
    $('.h01').after('<p class="mb30">貸金庫などの様々なサービスについてご案内いたします。</p>')
  }
  
  if ($('#wrapper').hasClass('ms2022-personal-pservice-direct')) {
    $('.h01 .h01').removeClass('h01')
    $('.h01').after($('.h01 > div'))
    $('.block01').addClass(['ms2022-feature', 'ms2022-feature--mod2'])
    $('.linkBtn').parent().children().wrapAll('<div class="ms2022-linkbtns"></div>')
  }

  if ($('#wrapper').hasClass('ms2022-personal-pservice-direct-logon')) {
    $('header').addClass('ms2022-header--with-close')
  }

  let reg = RegExp(/^ms2022-personal-pservice-direct-(payeasy_list|faq|information|payeasy|term|howto|flow|info01|info02|info03|info04|info09|info10) /)
  if (reg.test($('#wrapper').attr('class'))) {
    $('.h01 .h01').removeClass('h01')
    $('.h01').after($('.h01 > div'))
    $('.itemTable').wrap('<div class="ms2022-scrollable-table">')
    $('.linkBtn').parent().children().wrapAll('<div class="ms2022-linkbtns"></div>')
  }

  reg = RegExp(/^ms2022-personal-pservice-direct-(payeasy_term|term_all|term_net) /)
  if (reg.test($('#wrapper').attr('class'))) {
    $('.h01 .h01').removeClass('h01')
    $('.h01').after($('.h01 > div'))
  }
  
  reg = RegExp(/^ms2022-personal-pservice-direct-(faq01|faq02|faq03|faq04|faq05|faq06|faq07|faq08) /)
  if (reg.test($('#wrapper').attr('class'))) {
    $('.h01 .h01').removeClass('h01')
    $('.h01').after($('.h01 > div'))
    $('#main > .cf.mb40').addClass('ms2022-linkbtns')
  }
  
  reg = RegExp(/^ms2022-personal-pservice-direct-(howto|flow) /)
  if (reg.test($('#wrapper').attr('class'))) {
    $('.block01').eq(1).addClass('ms2022-feature')
  }

  if ($('#wrapper').hasClass('ms2022-personal-pservice-direct-information')) {
    $('table').wrap('<div class="ms2022-scrollable-table"></div>')

    const $serviceList = $('h2:contains("サービス内容") + ul.cf')
    const $springBoard = $('<div class="ms2022-spring-board">')
    $serviceList.find('li').each(function() {
      const url = $(this).find('a').attr('href')
      const name = $(this).find('img').attr('alt')
      const $item = $(`
        <a href="${url}" class="ms2022-spring-board__item">
          <i class="ms2022-spring-board__icon icon-loan"></i>
          <p class="ms2022-spring-board__title">${name}</p>
        </a>
      `)
      $springBoard.append($item)
    })
    $serviceList.replaceWith($springBoard)
  }

  if (
    $('#wrapper').hasClass('ms2022-personal-pservice-application') ||
    $('#wrapper').hasClass('ms2022-personal-pservice-application-index.html')
  ) {
    $('.ms2022-scrollable-table').addClass('ms2022-scrollable-table--onecol')

    const $appQr = $('<div class="ms2022-app-qr">')

    let href = $('a[href^="https://play.google.com"]').attr('href')
    let $box = $(`<a class="ms2022-app-qr__box" href="${href}" target="_blank">`)
    $box.append($('.photo[src$="Google.jpg"]'))
    $box.append($('.photo[src$="GooQR.jpg"]'))
    $appQr.append($box)
    
    href = $('a[href^="https://itunes.apple.com"]').attr('href')
    $box = $(`<a class="ms2022-app-qr__box" href="${href}" target="_blank">`)
    $box.append($('.photo[src$="APP.jpg"]'))
    $box.append($('.photo[src$="AppQR.jpg"]'))
    $appQr.append($box)
    
    console.log($('#main > table'));
    $('#main > table').replaceWith($appQr)

    $('div[style="border-radius: 10px; border: 4px double #ff6699; font-size: 100%; padding: 20px;"]')
      .attr('style', '')
      .addClass('borderBlock')
  }
}