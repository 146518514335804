import '../scss/ms2022.scss';

/**
 * コンポーネント
 */
import accordion from './components/accordion';
import backToTop from './components/back-to-top';
import bodyClass from './components/body-class';
import breadcrumb from './components/breadcrumb';
import card from './components/card';
import container from './components/container';
import footer from './components/footer';
import gnav from './components/gnav';
import head from './components/head';
import header from './components/header';
import headline from './components/headline';
import inquiry from './components/inquiry';
import itemTable from './components/item-table';
import linkButton from './components/link-button';
import loader from './components/loader';
import relationalButtons from './components/relational-buttons';
import sticky from './components/sticky';
import submenuButton from './components/submenu-button';
import table from './components/table';
import trim from './components/trim';

accordion()
backToTop()
bodyClass()
breadcrumb()
card()
container()
footer()
gnav()
head()
header()
headline()
inquiry()
itemTable()
linkButton()
loader()
relationalButtons()
sticky()
submenuButton()
table()
trim()

/**
 * ページ別
 */
import { fixed as homeFixed } from './pages/home';
import personal from './pages/personal/index';
import apply from './pages/personal/apply';
import confab from './pages/personal/confab';
import { fixed as loanFixed, switchable as loanSwitchable } from './pages/personal/loan';
import pservice from './pages/personal/pservice';
import { fixed as simulationFixed, switchable as simulationSwitchable } from './pages/personal/simulation';
import { fixed as corpFixed, switchable as corpSwitchable } from './pages/corp';
import cservice from './pages/corp/cservice';
import { fixed as gainFixed, switchable as gainSwitchable } from './pages/corp/gain';
import contact from './pages/others/contact';
import notice from './pages/notice';
import kinri from './pages/kinri';
import policy from './pages/policy';
import tesuryo from './pages/tesuryo';
import atm from './pages/atm';
import copyright from './pages/copyright';
import sitemap from './pages/sitemap';
import { fixed as aboutFixed, switchable as aboutSwitchable } from './pages/about';
import disclosure from './pages/about/disclosure';
import topics from './pages/topics';
import info from './pages/info';

homeFixed()
corpFixed()
aboutFixed()
loanFixed()
simulationFixed()
gainFixed()

if ($('body').hasClass('ms2022-custom-layout')) return

// 個人のお客さま
personal()
apply()
confab()
loanSwitchable()
pservice()
simulationSwitchable()

// 法人・事業主のお客さま
corpSwitchable()
cservice()
gainSwitchable()

// 企業・CSR情報
aboutSwitchable()
disclosure()

// その他
contact()
kinri()
notice()
policy()
tesuryo()
atm()
copyright()
sitemap()
topics()
info()