export default () => {
  const $topBlock = $('footer .inner .leftArea .topBlock')
  $topBlock.find('p.logo img').attr('src', '/common/image/logo_n.svg')
  $topBlock.find('p.txt').html('水戸信用金庫 金融機関コード：1240<br>登録金融機関 関東財務局長（登金）第227号')
  let items, headline, majorClassification

  $.getJSON('/ms2022-assets/sitemap.json', function(sitemap) {
    if ($('#wrapper').is('[class^="ms2022-corp"]')) {
      items = sitemap.corp
      headline = '法人・事業主のお客さま'
      majorClassification = ['個人のお客さま', '企業・CSR情報', '採用情報']
    } else if ($('#wrapper').is('[class^="ms2022-about"]')) {
      items = sitemap.about
      headline = '企業・CSR情報'
      majorClassification = ['個人のお客さま', '法人・事業主のお客さま', '採用情報']
    } else {
      items = sitemap.personal
      headline = '個人のお客さま'
      majorClassification = ['法人・事業主のお客さま', '企業・CSR情報', '採用情報']
    }
    
    $('footer').prepend(`
      <div class="ms2022-sitemap-index">
        <div class="ms2022-sitemap-index__inner">
          <p class="ms2022-sitemap-index__headline">${headline}</p>
          <div class="ms2022-sitemap-index__block">
            ${items.children.map(category => {
              if ('children' in category) {
                return `
                  <div class="ms2022-sitemap-index__item">
                    <div class="ms2022-sitemap-index__bar">${category.name}</div>
                    <div class="ms2022-sitemap-index__menu">
                      <p class="ms2022-sitemap-index__subhead">
                        <a href="${category.url}">
                          <span>${category.name}</span>
                          <span>${category.subname}</span>
                        </a>
                      </p>
                      ${template(category.children)}
                    </div>
                  </div>`
              } else {
                return `
                  <div class="ms2022-sitemap-index__bar--link">
                    <a href="${category.url}">${category.name}</a>
                  </div>`
              }
            }).join('')}
          </div>
          <div class="ms2022-sitemap-index__block">
            ${sitemap.other.children.map(child => {
              return `
                <div class="ms2022-sitemap-index__item">
                  <ul class="ms2022-sitemap-index__list--arrow">
                    ${child.map(item => {
                      return `<li><a href="${item.url}" ${item.external ? 'target="_blank"' : ''}>${item.name}</a></li>`
                    }).join('')}
                  </ul>
                </div>`
            }).join('')}
          </div>
          <div class="ms2022-sitemap-index__block">
            ${majorClassificationTemplate(majorClassification)}
          </div>
        </p>
      </div>`
    )
    
    $('.ms2022-sitemap-index__bar').on('click', ({ target }) => {
      if (!$(target).hasClass('is-show')) {
        $(target).addClass('is-show')
        const height = $(target).next()[0].scrollHeight
        $(target).next().height(height)
      } else {
        $(target).removeClass('is-show')
        $(target).next().height(0)
      }
    })
  })

}

function template(items) {
  return items.map(item => {
    if ('children' in item) {
      return `
        <p class="ms2022-sitemap-index__subhead">${item.name}</p>
        <ul class="ms2022-sitemap-index__list">
          ${item.children.map(c => {
            const blank = c.external ? 'target="_blank"' : ''
            return `<li><a href="${c.url}" ${blank}>${c.name}</a></li>`
          }).join('')}
        </ul>`
    } else {
      return `
        <ul class="ms2022-sitemap-index__list">
          <li><a href="${item.url}">${item.name}</a></li>
        </ul>`
    }
  }).join('')
}

function majorClassificationTemplate(list) {
  const array = [
    { url: '/', text: '個人のお客さま' },
    { url: '/corp/', text: '法人・事業主のお客さま' },
    { url: '/about/', text: '企業・CSR情報' },
    { url: '/saiyo/', text: '採用情報' }
  ]
  return `
    <ul class="ms2022-sitemap-index__major-classification">
      ${list.map(item => {
        const matched = array.find(i => i.text === item)
        return `<li><a href="${matched.url}">${matched.text}</a></li>`
      }).join('')}
    </ul>`
}